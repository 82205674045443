<template>
    <div>
        <div class="cookieCla_pc box"  v-show="cookFlag" ref="attrRef" v-if='onlinestate=="pc"' :style="{opacity:isFlashing?0:1}">
            <div v-if="island=='eng'">We use cookies to evaluate your use of the Website and to provide you with the best possible website experience. By continuing to browse this Website, you accept our use of cookies to collect data. For details, please refer to the Website's <a  style=" text-decoration: underline;color: #ffffff;">Privacy Data Policy</a> and <a  style=" text-decoration: underline;color: #ffffff;">Personal Data Collection Statement</a>.</div>
            <div v-else>本公司透过使用Cookies以评价阁下在本站点的使用情况，并为阁下提供最佳的网站体验。若阁下继续浏览本站点，即表示阁下接受本公司使用Cookies来收集数据。详情请参阅网上<span @click="a_Click('privacypolicy')" style=" cursor: pointer;text-decoration: underline;color: #000;">私隐政策</span>。</div>
            <!-- <div v-if="island=='eng'">We use cookies to evaluate your use of the Website and to provide you with the best possible website experience. By continuing to browse this Website, you accept our use of cookies to collect data. For details, please refer to the Website's <a href="https://www.qmisgroup.com/privacypolicy" style=" text-decoration: underline;color: #ffffff;">Privacy Data Policy</a> and <a href="https://www.qmisgroup.com/personalStatement" style=" text-decoration: underline;color: #ffffff;">Personal Data Collection Statement</a>.</div>
            <div v-else>本公司透過使用Cookies以評估閣下在本網站的使用情況，並為閣下提供最佳的網站體驗。若閣下繼續瀏覽本網站，即表示閣下接受本公司使用Cookies來收集數據。詳情請參閱網上 <a href="https://www.qmisgroup.com/privacypolicy" style=" text-decoration: underline;color: #ffffff;">私隱政策</a> 及 <a href="https://www.qmisgroup.com/personalStatement" style=" text-decoration: underline;color: #ffffff;">收集個人資料聲明</a>。</div> -->
            <div style="width:6rem"></div>
            <div class="cookBtn_pc" @click="cookClick" >
                <span v-if="island=='eng'">Confirm</span>
                <span v-else>确认</span>
            </div>
        </div>
        <div class="cookieCla" v-show="cookFlag"  ref="attrRef" v-else :style="{opacity:isFlashing?0:1}">
            <div v-if="island=='eng'" :style='{ fontSize:isshebie == "phone"? "14px":"14px"}'>We use cookies to evaluate your use of the Website and to provide you with the best possible website experience. By continuing to browse this Website, you accept our use of cookies to collect data. For details, please refer to the Website's <a  style=" text-decoration: underline;color: #ffffff;">Privacy Data Policy</a> and <a style=" text-decoration: underline;color: #ffffff;">Personal Data Collection Statement</a>.</div>
            <div v-else :style='{ fontSize:isshebie == "phone"? "14px":"14px"}'>本公司透过使用Cookies以评价阁下在本站点的使用情况，并为阁下提供最佳的网站体验。若阁下继续浏览本站点，即表示阁下接受本公司使用Cookies来收集数据。详情请参阅网上<span @click="a_Click('privacypolicy')" style=" text-decoration: underline;color: #000;">私隐政策</span>。</div>
            <!-- <div v-if="island=='eng'" :style='{ fontSize:isshebie == "phone"? "14px":"14px"}'>We use cookies to evaluate your use of the Website and to provide you with the best possible website experience. By continuing to browse this Website, you accept our use of cookies to collect data. For details, please refer to the Website's <a href="https://www.qmisgroup.com/privacypolicy" style=" text-decoration: underline;color: #ffffff;">Privacy Data Policy</a> and <a href="https://www.qmisgroup.com/personalStatement" style=" text-decoration: underline;color: #ffffff;">Personal Data Collection Statement</a>.</div>
            <div v-else :style='{ fontSize:isshebie == "phone"? "14px":"14px"}'>本公司透過使用Cookies以評估閣下在本網站的使用情況，並為閣下提供最佳的網站體驗。若閣下繼續瀏覽本網站，即表示閣下接受本公司使用Cookies來收集數據。詳情請參閱網上 <a href="https://www.qmisgroup.com/privacypolicy" style=" text-decoration: underline;color: #ffffff;">私隱政策</a> 及 <a href="https://www.qmisgroup.com/personalStatement" style=" text-decoration: underline;color: #ffffff;">收集個人資料聲明</a>。</div> -->
            <br>
            <span class="cookBtn" @click="cookClick">
                <span v-if="island=='eng'">Confirm</span>
                <span v-else>确认</span>
            </span>
        </div>
    </div>
</template>

<script>
    export default {
        data() {
            return{
                isFlashing: false,
                cookFlag:false,
                island: 'big5',
                onlinestate:sessionStorage.onlinestate,
            }
        },
        mounted() {
            if(!window.localStorage['cookie_second']){
                this.cookFlag = true;
            }
            this.isFlashing = true;
            setTimeout(() => {
                this.isFlashing = false;
            }, 150); // 闪动时间，可以根据需要调整
        },
        methods: {
            cookClick(){
                window.localStorage['cookie_second'] = 1
                this.cookFlag = false;
                // this.$router.go(0)
            },
            a_Click(val){
                // 部署正式
                window.open('https://qmisit.com/#/privacypolicy','_self')
                // window.open('http://47.106.201.148/qmisitweb/#/privacypolicy','_self')
                // window.open('http://192.168.31.221:8081/qmisitweb/#/privacypolicy','_self')
                setTimeout(() => {
                    window.location.reload();
                }, 500);
                // this.$router.push({
                //     path: '/privacypolicy'
                // });
                // this.isFlashing = true;
                // setTimeout(() => {
                //     this.isFlashing = false;
                // }, 500); // 闪动时间0.5秒，可以根据需要调整
                // if (this.$route.path == '/privacypolicy') {
                //     window.location.reload();
                // }
            },
           
        }
        
    }
</script>

<style >
  .cookieCla_pc{
    z-index: 3;
    padding:1rem 12.5%;
    position: fixed;
    bottom: 0;
    background: #f5f5f5; 
    width: 100%;
    display: flex;
    align-items: center;
    color: #000;
  }
  .cookBtn_pc{
    width:10rem;
    border: 1px solid #000;
    margin-left: auto;
    padding: 0.5rem 0;
    cursor: pointer;
    color: #000;
    flex-shrink: 0;
    display:flex;
    justify-content: center;
    align-items: center;
    border-radius:0.5rem;
  }
  .cookBtn_pc:hover{
    border:none;
    background:#0e626c;
    color:#fff;
  }

  .cookieCla{
    z-index: 3;
    padding:15px;
    position: fixed;
    bottom: 0;
    background: #f5f5f5; 
    width: 100%;
    color: #000;
  }
  .cookBtn{
    width:100%;
    border: 1px solid #000;
    padding: 10px;
    cursor: pointer;
    color: #000;
    display:flex;
    justify-content: center;
    align-items: center;
    border-radius:5px;
    font-size:16px;
  }
  .box{
    transition: opacity .15s linear;
  }
</style>