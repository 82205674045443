<template>
	<div id="post" :class="[onlinestate=='ipad'?'ipad_class':onlinestate == 'phone'?'phone_class':'pc_class']">
		<div class='heard'>
			<div id='wrap_hand' class='wrap_hand wrap_hand_animation'>
				<div class='heard_left myun_down' @click="backClick()" style="cursor: pointer;"><img src="@/image/onhand/logo.png" alt=""></div>
				<div class='heard_left mydown'><img src="@/image/onhand/logo_pc.png" alt=""></div>
				<div class='heard_right mydown'><img src="@/image/onhand/icon.png" alt=""></div>
				<div class='heard_right myun_down'>
					<section class="mod model-1">
						<div class="menu" @click="menuPage()" :class="menuFlag ?'menu menu_hover':'menu'">
							<div class="bar"></div>
							<div class="bar"></div>
							<div class="bar"> </div>
						</div>
					</section>
				</div>
			</div>
        </div>
        <div>
			<img :src="banner.banner_url_web" alt="" style="width:100%;" v-if="onlinestate == 'pc'">
            <img :src="banner.banner_url_app" alt="" style="width:100%;" v-else>
            <div class="text_aboutUs">
				<div style="display:flex;justify-content: space-between;" v-if="onlinestate == 'pc'">
					<div class="post_tab" :style="{'color': tabValue == item.id ? '#40bfbf':'','border' : jobVacancy.length-index<=0 ? 'none':''}" @click=" tabValue = item.id ,getdate()" v-for="(item,index) in jobVacancy" :key="index">{{ item.job_name }}</div>
				</div>
				<div style="display:flex;justify-content: space-between;overflow-x: scroll;" class="element" v-else>
					<div class="post_tab_phone" :style="{'color': tabValue == item.id ? '#40bfbf':'','border' : jobVacancy.length-index<=0 ? 'none':''}" @click="tabValue = item.id,getdate()" v-for="(item,index) in jobVacancy" :key="index">{{ item.job_name }}</div>
				</div>
				<div >
					<div style="font-weight:600;margin: 20px 0;">{{params.jobVacancy.job_name}}</div>
					<div class="title_text">
						<div></div>
						职位责任
					</div>
					<ul style="margin-top: 10px;" :style="{marginLeft:onlinestate == 'pc' ?'3%': onlinestate == 'ipad' ?'5%':'10%'}">
						<li type='disc' v-for="(item,index) in  params.jobDuty" :key="index">{{ item.job_duty }}</li>
					</ul>
					<div class="title_text">
						<div></div>
						职位要求
					</div>
					<ul style="margin-top: 10px;" :style="{marginLeft:onlinestate == 'pc' ?'3%': onlinestate == 'ipad' ?'5%':'10%'}">
						<li type='disc' v-for="(item,index) in  params.jobRequirements" :key="index">{{ item.job_requirements }}</li>
					</ul>
				</div>
				<!-- <div v-if="tabValue == 1">
					<div style="font-weight:600;margin: 20px 0;">UI 设计师</div>
					<div class="title_text">
						<div></div>
						职位责任
					</div>
					<ul style="margin-top: 10px;" :style="{marginLeft:onlinestate == 'pc' ?'3%': onlinestate == 'ipad' ?'5%':'10%'}">
						<li type='disc'>根据公司项目及相关主题，绘制插图及平面设计;</li>
						<li type='disc'>完成公司产品整体美术设计，做到结构合理，美观实用，色彩感强;</li>
						<li type='disc'>负责线上推广活动相关的视觉设计，包括页面、活动、专题、Banner等。</li>
					</ul>
					<div class="title_text">
						<div></div>
						职位要求
					</div>
					<ul style="margin-top: 10px;" :style="{marginLeft:onlinestate == 'pc' ?'3%': onlinestate == 'ipad' ?'5%':'10%'}">
						<li type='disc'>本科及以上学历，平面设计、视觉传达等相关专业;</li>
						<li type='disc'>熟练掌握Photoshop、 CorelDraw、indesign Acrobat、Illustrator等常用工具软件;</li>
						<li type='disc'>会手绘或熟练使用AE/PR视频剪辑者优先;</li>
						<li type='disc'>具有独特的创意和良好的视觉构图及美学欣赏能力;</li>
						<li type='disc'>具有扎实的美术功底及审美能力，对色彩把握准确，具有把握不同设计风格的能力;</li>
						<li type='disc'>设计兴趣浓厚，并有良好的创新意识，对国际设计有良好的洞悉能力;</li>
						<li type='disc'>主动性高，善于沟通、耐心细致，能准确表达设计思路，有较强的责任心及较强的理解分析、创意设计能力和色彩搭配能力。</li>
					</ul>
				</div>
				<div v-if="tabValue == 2">
					<div style="font-weight:600;margin: 20px 0;">iOS 工程师</div>
					<div class="title_text">
						<div></div>
						职位责任
					</div>
					<ul style="margin-top: 10px;" :style="{marginLeft:onlinestate == 'pc' ?'3%': onlinestate == 'ipad' ?'5%':'10%'}">
						<li type='disc'>负责公司现有项目和新项目的iOS应用修改调试和开发工作;</li>
						<li type='disc'>根据需求完成架构和模块设计、编码、测试工作;</li>
						<li type='disc'>独立、按时、高质完成开发任务。</li>
					</ul>
					<div class="title_text">
						<div></div>
						职位要求
					</div>
					<ul style="margin-top: 10px;" :style="{marginLeft:onlinestate == 'pc' ?'3%': onlinestate == 'ipad' ?'5%':'10%'}">
						<li type='disc'>计算机相关专业专科及以上学历;</li>
						<li type='disc'>精通object-C语言,熟悉iOS SDK,熟悉常用的数据结构和算法;</li>
						<li type='disc'>3年以上iOS产品开发经验,有上线产品优先考虑;</li>
						<li type='disc'>精通iOS各类UI组件,自定义View,图像动画,网络,文件系统等模块的使用和开发;</li>
						<li type='disc'>精通多线程开发,有良好的代码习惯,对iOS应用架构有深入的理解; </li>
						<li type='disc'>精通iOS的性能优化,UI优化,内存优化等;</li>
						<li type='disc'>掌握常用设计模式,熟悉主流的开源框架;</li>
						<li type='disc'>思路清晰,思维敏捷,快速的学习能力,较强的分析问题和解决问题的能力;</li>
						<li type='disc'>工作认真,积极主动,能承担较大的工作压力,具备良好的沟通能力和团队合作精神。</li>
					</ul>
				</div>
				<div v-if="tabValue == 3">
					<div style="font-weight:600;margin: 20px 0;">C++ 工程师</div>
					<div class="title_text">
						<div></div>
						职位责任
					</div>
					<ul style="margin-top: 10px;" :style="{marginLeft:onlinestate == 'pc' ?'3%': onlinestate == 'ipad' ?'5%':'10%'}">
						<li type='disc'>根据工作安排高效、高质地完成代码编写;</li>
						<li type='disc'>负责公司现有项目和新项目程序调试和开发工作;</li>
						<li type='disc'>与后端开发团队紧密配合，确保代码有效对接。</li>
					</ul>
					<div class="title_text">
						<div></div>
						职位要求
					</div>
					<ul style="margin-top: 10px;" :style="{marginLeft:onlinestate == 'pc' ?'3%': onlinestate == 'ipad' ?'5%':'10%'}">
						<li type='disc'>本科及以上学历，计算机或相关专业毕业;</li>
						<li type='disc'>熟悉Windows/Linux平台下软件开发流程，具有良好的编码习惯;</li>
						<li type='disc'>二年以上C++、QT开发经验，精通设计模式;</li>
						<li type='disc'>熟悉传输层协议， TCP/IP 协议，UDP/IP协议;</li>
						<li type='disc'>精通MFC，熟练使用boost 3个以上库;</li>
						<li type='disc'>工作积极主动，责任心强，诚实可信;</li>
						<li type='disc'>工作细致、严谨，承压能力强，善于沟通。</li>
					</ul>
				</div>
				<div v-if="tabValue == 4">
					<div style="font-weight:600;margin: 20px 0;">Android 工程师</div>
					<div class="title_text">
						<div></div>
						职位责任
					</div>
					<ul style="margin-top: 10px;" :style="{marginLeft:onlinestate == 'pc' ?'3%': onlinestate == 'ipad' ?'5%':'10%'}">
						<li type='disc'>负责公司现有项目和新项目的Android应用修改调试和开发工作;</li>
						<li type='disc'>根据需求完成架构和模块设计、编码、测试工作;</li>
						<li type='disc'>独立、按时、高质完成开发任务。</li>
					</ul>
					<div class="title_text">
						<div></div>
						职位要求
					</div>
					<ul style="margin-top: 10px;" :style="{marginLeft:onlinestate == 'pc' ?'3%': onlinestate == 'ipad' ?'5%':'10%'}">
						<li type='disc'>计算机相关专业专科及以上学历;</li>
						<li type='disc'>精通Java语言,熟悉Android SDK,熟悉常用的数据结构和算法;</li>
						<li type='disc'>3年以上Android产品开发经验,有上线产品优先考虑;</li>
						<li type='disc'>精通Android各类UI组件,自定义View,图像动画,网络,文件系统等模块的使用和开发;</li>
						<li type='disc'>精通多线程开发,有良好的代码习惯,对Android应用架构有深入的理解;</li>
						<li type='disc'>精通Android的性能优化,UI优化,内存优化等;</li>
						<li type='disc'>掌握常用设计模式,熟悉主流的开源框架(Rx Java,Retrofit,ButterKnife等);</li>
						<li type='disc'>思路清晰,思维敏捷,快速的学习能力,较强的分析问题和解决问题的能力;</li>
						<li type='disc'>工作认真,积极主动,能承担较大的工作压力,具备良好的沟通能力和团队合作精神。</li>
					</ul>
				</div>
				<div v-if="tabValue == 5">
					<div style="font-weight:600;margin: 20px 0;">Web 前端工程师</div>
					<div class="title_text">
						<div></div>
						职位责任
					</div>
					<ul style="margin-top: 10px;" :style="{marginLeft:onlinestate == 'pc' ?'3%': onlinestate == 'ipad' ?'5%':'10%'}">
						<li type='disc'>根据工作安排高效、高质地完成代码编写，确保符合规范的前端代码规范;</li>
						<li type='disc'>负责公司现有项目和新项目的前端修改调试和开发工作;</li>
						<li type='disc'>与团队紧密配合，能够实现实现设计师的设计想法;</li>
						<li type='disc'>与后端开发团队紧密配合，确保代码有效对接，优化网站前端性能;</li>
						<li type='disc'>页面通过标准校验，兼容各主流浏览。</li>
					</ul>
					<div class="title_text">
						<div></div>
						职位要求
					</div>
					<ul style="margin-top: 10px;" :style="{marginLeft:onlinestate == 'pc' ?'3%': onlinestate == 'ipad' ?'5%':'10%'}">
						<li type='disc'>本科及以上学历，计算机或相关专业毕业;</li>
						<li type='disc'>2-3年以上前端开发经验，熟悉JavaScript、html/html5、css/css3、Ajax等前端开发技术;</li>
						<li type='disc'>熟练使用至少一种JS框架，例如AngularJS Vue等，能独立开发常用组件;</li>
						<li type='disc'>了解至少一门后端语言（如java，php，nodejs等）;</li>
						<li type='disc'> 有PC端浏览器适配及手机端分辨率适配优先;</li>
						<li type='disc'> 具有良好的沟通能力和团队合作精神、优秀的分析问题和解决问题的能力。</li>
					</ul>
				</div>
				<div v-if="tabValue == 6">
					<div style="font-weight:600;margin: 20px 0;">Java 后端工程师</div>
					<div class="title_text">
						<div></div>
						职位责任
					</div>
					<ul style="margin-top: 10px;" :style="{marginLeft:onlinestate == 'pc' ?'3%': onlinestate == 'ipad' ?'5%':'10%'}">
						<li type='disc'>根据工作安排高效、高质地完成代码编写，确保符合规范的后端代码规范;</li>
						<li type='disc'>负责现有项目和新项目的后端修改调试和开发工作;</li>
						<li type='disc'>根据项目需求编辑代码规范文案;</li>
						<li type='disc'>与前端，移动端开发团队紧密配合，确保代码有效对接，优化后端运行性能;</li>
					</ul>
					<div class="title_text">
						<div></div>
						职位要求
					</div>
					<ul style="margin-top: 10px;" :style="{marginLeft:onlinestate == 'pc' ?'3%': onlinestate == 'ipad' ?'5%':'10%'}">
						<li type='disc'>本科及以上学历，计算机或相关专业毕业优先;</li>
						<li type='disc'>2年以上Java后端开发经验，编程基础扎实，具有良好的编程习惯和代码编写能力，可熟练使用eclipse开发工具;</li>
						<li type='disc'>熟悉前后端分离开发模式，与前端UI配合联调;</li>
						<li type='disc'>熟悉MySQL数据库，熟悉非关系型数据库如Redis等，具有数据库优化经验;</li>
						<li type='disc'>熟悉面向对象设计模式，具备SpringBoot/myBatis等开源框架应用经验;</li>
						<li type='disc'>具有较强的解决问题关联性，避免重复调试;</li>
						<li type='disc'>具有良好的沟通能力和团队合作精神、优秀的分析问题和解决问题的能力。</li>
					</ul>
				</div> -->
            </div>
            <div style="background-color: #0d626b;position: fixed;width: 100%;bottom: 0;">
                <div class='home_bottoom' v-if="onlinestate == 'pc'">
                    <div style="font-size: 13px;color: #fff;">
                        © 2022大连铧亚软件科技开发有限公司版权所有
                    </div>
                    <div style="font-size: 13px;color: #fff;">
                        辽ICP备2021010794号-1
                    </div>
                </div>
                <div class='home_bottoom_phone' v-else>
                    <div style="font-size: 13px;color: #fff;padding: 5px 0;">
                        © 2022大连铧亚软件科技开发有限公司版权所有
                    </div>
                    <div style="font-size: 13px;color: #fff;padding: 5px 0;">
                        辽ICP备2021010794号-1
                    </div>
                </div>
            </div>
			<div style="height: 20px;"  v-if="onlinestate == 'phone'"></div>
        </div>
        <div class="menu_div" v-if="menuFlag" @click="Move()">
			<div style="text-align: center;color: #ffffff;" class="menu_class">
				<div class="back_hover" style="cursor: pointer;" @click="skipClick(1)">主页</div>
				<div class="back_hover" style="margin-top:10px;cursor: pointer;" @click="skipClick(2)">关于我们</div>
				<div class="back_hover" style="margin-top:10px;cursor: pointer;" @click="skipClick(3)">服务范畴</div>
				<div class="back_hover" style="margin-top:10px;cursor: pointer;" @click="skipClick(4)">作品案例</div>
				<div class="back_hover" style="margin-top:10px;cursor: pointer;color:#40bfbf;" @click="skipClick(5)">就业机会</div>
				<div class="back_hover" style="margin-top:10px;cursor: pointer;" @click="skipClick(6)">联络我们</div>
			</div>
		</div>
		<cookie></cookie>
	</div>
</template>

<script>
	import AOS from "aos";
	import cookie from '../../cookies/cookie.vue'
	export default {
		components: {
			cookie
		},
		name: 'post',
		data() {
			return {
				onlinestate:sessionStorage.onlinestate,
				tabValue:this.$route.query.id,
				menuFlag:false,
				params:{},
				banner:{},
				jobVacancy:[],
			}
		},
		mounted() {
			this.getdate()
			this.getdate1()
			window.scrollTo({
				top: 0,
				behavior: 'smooth' // 平滑滚动效果
			});
			this.tabClick()
			// AOS.init();
			// // 你也可以在这里设置全局配置
			// AOS.init({
			// 	offset: 180,
			// 	duration: 400, //持续时间
			// 	easing: 'ease-in-sine',
			// 	delay: 300,
			// 	// once:true,
			// })
			// var scrolltop = '';
			// // let mybottomdom = '';
			// // setTimeout(()=>{
			// // 	 mybottomdom = document.getElementById('mybottom').offsetTop
			// // },500)
			// window.onscroll = function(e) {
			// 	this.isscrollnum = JSON.parse(JSON.stringify(e))
			// 	let island = 1.5
			// 	//判断scroll是否向上还是向下 比较当前的值是否比之前值的大小
			// 	if (scrolltop > this.isscroll) { //向下
			// 		island = 1.5
			// 		document.getElementById('wrap_hand').className = 'wrap_hand'
			// 	} else { //上
			// 		island = -1.5
			// 		document.getElementById('wrap_hand').className = 'wrap_hand wrap_hand_animation'
			// 	}
			// 	if (scrolltop < 50) {
			// 		document.getElementById('mytitle_img').className = 'mytitle_img'
			// 	} else if (scrolltop > 857) {
			// 		document.getElementById('mytitle_img').className = ' ';
			// 	}
			// 	this.isscroll = scrolltop;
			// 	scrolltop = document.documentElement.scrollTop;
			// }
		},
		methods: {
			getdate(){
				this.$ajax({
					method:'post',
					url:this.$api.getPositionPage,
					data:{
						id:this.tabValue
					}
				}).then(res=>{
					if(res.data.qmiscode === 1000){
						this.params = res.data
					}else{
						this.$message.warning(res.data.qmismsg)
					}
				})
			},
			getdate1(){
				this.$ajax({
					method:'post',
					url:this.$api.getAboutUsPage,
					data:{
						page_name:"aboutUs"
					}
				}).then(res=>{
					if(res.data.qmiscode === 1000){
						this.banner = res.data.banner
						this.jobVacancy = res.data.jobVacancy
					

					}else{
						this.$message.warning(res.data.qmismsg)
					}
				})
			},
			backClick(){
				if (location.hostname == "qmisit.com") {
					window.location.href = "https://qmisit.com/#/";
				} else {
					window.location.href = "http://47.106.201.148/qmisitweb/#/?id=0";
				}
				// this.$router.push({ path: '/', query: { id: 0 }  });
			},
			tabClick(){
				var element =  document.querySelector('.element')
				if (this.tabValue == 2) {
					element.scrollLeft = 100;
				}
				if (this.tabValue == 3) {
					element.scrollLeft = 200;
				}
				if (this.tabValue == 4) {
					element.scrollLeft = 300;
				}
				if (this.tabValue == 5) {
					element.scrollLeft = 450;
				}
				if (this.tabValue == 6) {
					element.scrollLeft = 550;
				}
			},
            skipClick(val){
                if (val == 1) {
                    this.$router.push({
						path: '/', 
						query: { id: 0 } 
					});
                }
                if (val == 2) {
                    this.$router.push({
						path: '/aboutUs', 
						query: { id: 2 } 
					});
                }
				if (val == 3) {
                    this.$router.push({
						path: '/', 
						query: { id: 3 } 
					});
                }
                if (val == 4) {
                    this.$router.push({
						path: '/', 
						query: { id: 4 } 
					});
                }
                if (val == 5) {
                    this.$router.push({
						path: '/aboutUs', 
						query: { id: 5 } 
					});
                }
				if (val == 6) {
                    this.$router.push({
						// path: '/contactUs', 
						path: '/', 
						query: { id: 6 } 
					});
                }
            },
			menuPage(){
				console.log("菜單111111");
				this.menuFlag = !this.menuFlag
				if(this.menuFlag){
					this.stopMove()
				}else{
					this.Move()
				}
			},
			stopMove(){
				// let m = function(e){e.preventDefault();};
				// document.body.style.overflow='hidden';
				// document.addEventListener("touchmove",m,{ passive:false });//禁止页面滑动
			},
			//开启页面滚动
			Move(){
				this.menuFlag = false
				let m =function(e){e.preventDefault();};
				document.body.style.overflow='';//出现滚动条
				document.removeEventListener("touchmove",m,{ passive:true });
			},

		}
	}
</script>
<style lang='less' scoped>

	* {
		box-sizing: border-box;
		-moz-box-sizing: border-box;
		-webkit-box-sizing: border-box;
		font-family: arial;
	}
	body {
		padding: 0;
		margin: 0;
		text-align: center;
		font-family: arial;
	}
	section {
		// height: 300px;
		// padding-top: 100px;
		float: left;
		width: 50%;
		position: relative;
		/*==============++++================*/
	}
	.menu {
		height: 28px;
		width: 28px;
		position: relative;
		margin: auto;
		// padding-top: 20px;
		border: 5px solid transparent;
		-moz-border-radius: 100%;
		-webkit-border-radius: 100%;
		border-radius: 100%;
		-moz-transition: 0.3s;
		-o-transition: 0.3s;
		-webkit-transition: 0.3s;
		transition: 0.3s;
		cursor: pointer;
	}
	.bar {
		height: 3px;
		width: 28px;
		display: block;
		// margin: 10px auto;
		position: relative;
		background-color: #000;
		-moz-border-radius: 10px;
		-webkit-border-radius: 10px;
		border-radius: 10px;
		-moz-transition: 0.4s;
		-o-transition: 0.4s;
		-webkit-transition: 0.4s;
		transition: 0.4s;
	}
	.model-1 .bar {
		position: absolute;
	}
	.model-1 .bar:nth-of-type(1) {
		// top: 15px;
		-moz-transition: top 0.3s ease 0.3s, -moz-transform 0.3s ease-out 0.1s;
		-o-transition: top 0.3s ease 0.3s, -o-transform 0.3s ease-out 0.1s;
		-webkit-transition: top 0.3s ease, -webkit-transform 0.3s ease-out;
		-webkit-transition-delay: 0.3s, 0.1s;
		transition: top 0.3s ease 0.3s, transform 0.3s ease-out 0.1s;
		-moz-animation: mrotr 2s cubic-bezier(0.5, 0.2, 0.2, 1.01);
		-webkit-animation: mrotr 2s cubic-bezier(0.5, 0.2, 0.2, 1.01);
		animation: mrotr 2s cubic-bezier(0.5, 0.2, 0.2, 1.01);
	}
	.model-1 .bar:nth-of-type(2) {
		top: 10px;
		-moz-transition: 0.3s ease 0.3s;
		-o-transition: 0.3s ease 0.3s;
		-webkit-transition: 0.3s ease;
		-webkit-transition-delay: 0.3s;
		transition: 0.3s ease 0.3s;
		-moz-animation: fade 2s cubic-bezier(0.5, 0.2, 0.2, 1.01);
		-webkit-animation: fade 2s cubic-bezier(0.5, 0.2, 0.2, 1.01);
		animation: fade 2s cubic-bezier(0.5, 0.2, 0.2, 1.01);
	}
	.model-1 .bar:nth-of-type(3) {
		top: 20px;
		-moz-transition: top 0.3s ease 0.3s, -moz-transform 0.3s ease-out 0.1s;
		-o-transition: top 0.3s ease 0.3s, -o-transform 0.3s ease-out 0.1s;
		-webkit-transition: top 0.3s ease, -webkit-transform 0.3s ease-out;
		-webkit-transition-delay: 0.3s, 0.1s;
		transition: top 0.3s ease 0.3s, transform 0.3s ease-out 0.1s;
		-moz-animation: mrotl 2s cubic-bezier(0.5, 0.2, 0.2, 1.01);
		-webkit-animation: mrotl 2s cubic-bezier(0.5, 0.2, 0.2, 1.01);
		animation: mrotl 2s cubic-bezier(0.5, 0.2, 0.2, 1.01);
	}
	.model-1 .menu_hover .bar:nth-of-type(1) {
		top: 10px;
		-moz-transform: rotate(45deg);
		-ms-transform: rotate(45deg);
		-webkit-transform: rotate(45deg);
		transform: rotate(45deg);
		-moz-transition: top 0.3s ease 0.1s, -moz-transform 0.3s ease-out 0.5s;
		-o-transition: top 0.3s ease 0.1s, -o-transform 0.3s ease-out 0.5s;
		-webkit-transition: top 0.3s ease, -webkit-transform 0.3s ease-out;
		-webkit-transition-delay: 0.1s, 0.5s;
		transition: top 0.3s ease 0.1s, transform 0.3s ease-out 0.5s;
	}
	.model-1 .menu_hover .bar:nth-of-type(2) {
		opacity: 0;
	}
	.model-1 .menu_hover .bar:nth-of-type(3) {
		top: 10px;
		-moz-transform: rotate(-45deg);
		-ms-transform: rotate(-45deg);
		-webkit-transform: rotate(-45deg);
		transform: rotate(-45deg);
		-moz-transition: top 0.3s ease 0.1s, -moz-transform 0.3s ease-out 0.5s;
		-o-transition: top 0.3s ease 0.1s, -o-transform 0.3s ease-out 0.5s;
		-webkit-transition: top 0.3s ease, -webkit-transform 0.3s ease-out;
		-webkit-transition-delay: 0.1s, 0.5s;
		transition: top 0.3s ease 0.1s, transform 0.3s ease-out 0.5s;
	}
	.menu_class{
		font-size: 26px;
	}
	.post_tab{
		border-right: 1px solid #d8d8d8;
		width: 100%;
		text-align: center;
		cursor: pointer;
	}
	.post_tab_phone{
		border-right: 1px solid #d8d8d8;
		flex-shrink: 0;
		padding: 0 10px;
		text-align: center;
		cursor: pointer;
	}
    .back_hover:hover{
        color: #40bfbf;
    }
	.title_text{
        display: flex;
        align-items: center;
        font-weight: 600;
    }
    .title_text div{
        // margin-top: 5px;
        margin-right: 10px;
        width: 4px;
        height: 18px;
        background: #40bfbf;
    }
	.home_bottoom{
		width: 90%;margin: 5px auto;
		display: flex;align-items: center;
		justify-content: space-between;
		height: 45px;
		padding: 10px 0;
	}
	.home_bottoom_phone{
		width: 90%;
		margin: 5px auto;
	}
    .text_aboutUs{
        width: 50%;
        margin: 30px auto;
		padding-bottom: 100px;
    }

	.menu_div{
		width: 100%;
		height: 100%;
		background-color: rgba(0, 0, 0, 0.6);
		position: fixed;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		display: flex;
		justify-content: center;
		align-items: center;
	}


	body{
		color: #000;
	}
	.mydown>img {
		width: 1rem;
		cursor: pointer;
	}

	.mydown {
		position: absolute;
		bottom: -8rem;
		left: 49.5%;
	}

	.mydown img:hover {
		margin-top: .5rem;
		transition: .3s ease-in-out;
	}


	.heard {
		background-color: #f5f5f5;
	}

	.post {
		width: 100%;
		overflow: hidden;
	}



	.heard_left {
		float: left;
		padding: 10px;
	}
	.heard_left img {
	width: 17rem;
	}

	.heard_right {
		float: right;
		padding-top: 1.3rem;
	}

	.wrap_hand {
		/* display: inline-block; */
		overflow: hidden;
		position: absolute;
		width: 100%;
		/* left: 4%; */
		padding: 10px 4%;
		z-index: 100;
		background: rgba(255, 255, 255, 0.7);
		box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
	}

	.wrap_hand_animations {
		position: fixed;
		top: 0;
	}

	.wrap_hand_animation {
		.heard_left {
			padding: 0;
		}

		.wrap_hand {
			padding: 8px 4%;
		}
	}
	
	.genlist{
		text-align: center;width: 25%;margin: 1rem 0 3rem;
	}
	.fagenlist{
		display: flex;justify-content: space-evenly;
	}
	
	.wrap_hand_animation {
		position: fixed;
		top: 0;
		-webkit-animation-name: hand-out;
		-moz-animation-name: hand-out;
		-o-animation-name: hand-out;
		animation-name: hand-out;
		-webkit-animation-duration: .5s;
		-moz-animation-duration: .5s;
		-o-animation-duration: .5s;
		animation-duration: .5s;
		background: rgba(255, 255, 255, .7);
	}

	.wrap_hand_animation img {
		-webkit-transition: padding 0.3s linear;
		-moz-transition: padding 0.3s linear;
		-o-transition: padding 0.3s linear;
		transition: padding 0.3s linear;
	}

	@keyframes hand-out {
		0% {
			-webkit-transform: translateY(-110px)
		}

		100% {
			-webkit-transform: translateY(0)
		}
	}

	@-webkit-keyframes hand-out {
		0% {
			-webkit-transform: translateY(-110px)
		}

		100% {
			-webkit-transform: translateY(0)
		}
	}

	@-moz-keyframes hand-out {
		0% {
			-webkit-transform: translateY(-110px)
		}

		100% {
			-webkit-transform: translateY(0)
		}
	}

	@-o-keyframes hand-out {
		0% {
			-webkit-transform: translateY(-110px)
		}

		100% {
			-webkit-transform: translateY(0)
		}
	}

	.mytitle_img {
		-webkit-animation-name: zoom-out;
		-moz-animation-name: zoom-out;
		-o-animation-name: zoom-out;
		animation-name: zoom-out;
		-webkit-animation-duration: 10s;
		-moz-animation-duration: 10s;
		-o-animation-duration: 10s;
		animation-duration: 10s;
		width: 100vw;height: 101vh;
	}

	@-webkit-keyframes zoom-out {
		0% {
			-webkit-transform: scale(1);
		}

		100% {
			-webkit-transform: scale(1.2);
		}
	}

	@-moz-keyframes zoom-out {
		0% {
			-webkit-transform: scale(1);
		}

		100% {
			-webkit-transform: scale(1.2);
		}
	}

	@-o-keyframes zoom-out {
		0% {
			-webkit-transform: scale(1);
		}

		100% {
			-webkit-transform: scale(1.2);
		}
	}

	@keyframes zoom-out {
		0% {
			-webkit-transform: scale(1);
		}

		100% {
			-webkit-transform: scale(1.2);
		}
	}

	@media screen and(max-width:980px){
		.menu_class{
			font-size: 4.5rem;
		}
		.text_aboutUs{
			width: 90%;
		}
		.heard_left img {
			width: 63rem;
		}
		.heard_right{
			padding-top: 3.3rem;
			padding-right: 3.3rem;
		}
		.genlist img{
			width: 15rem;
		}
		.genlist{
			margin: 1rem 0 5.5rem;
		}
		/* .fagenlist{
			display: inline-block;
			justify-content: center;
			text-align: center;
		} */
		.myname{
			font-weight: 500;margin: 3.5rem 13px 0;font-size: 16px;color: #000000;
		}
		.mytitle_img {
			-webkit-animation-name: zoom-out;
			-moz-animation-name: zoom-out;
			-o-animation-name: zoom-out;
			animation-name: zoom-out;
			-webkit-animation-duration: 10s;
			-moz-animation-duration: 10s;
			-o-animation-duration: 10s;
			animation-duration: 10s;
			width: 100vw;
			height: 85rem;
		}

		.mydown{
			display: none;
		}
		.heard_left{
			padding: 0;
		}
	}
	.ipad_class{
		.heard_left img{
			width: 35rem;
		}
		.heard_right{
			padding-top: 2rem;
		}
		.menu_class{
			font-size: 26px !important;
		}
	}
</style>
